.div-admin-dashboard-main {
  height: 100svh !important;
  width: 120vw !important;
  width: 100% !important;
  gap: 60px !important;
}

.div-admin-dashboard-main-child {
  gap: 10px;
}

.admin-add-above-table-button {
  height: 40px;
  width: 150px;
  color: #efefef;
  background-color: #ff6016;
  border-radius: 5px;
  border: 1px solid #ff6016;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

.admin-add-above-table-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid black;
  border-radius: 5px;
  font-weight: bold;
}
