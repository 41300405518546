@font-face {
  font-family: regular;
  src: url("../../Assets/GFSDidot-Regular.ttf");
}

.css-1uwgr7b-MuiTypography-root {
  font-family: regular !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  margin: 0 !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  text-align: center !important;
}

.css-1oy63y8 {
  margin: 0px;
  font-family: regular !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: center;
}

.Navbar-main-Containerr {
  color: #ff6016 !important;
  background-color: rgb(0, 0, 0) !important;
  padding-left: 95px !important;
  padding-right: 95px !important;
  font-family: regular !important;
  min-width: 100vw !important;
  width: 100%;
  position: fixed !important;
  top: 0 !important;
  z-index: 100;
}
.list-logo-navbar-up-right-cr7 {
  display: none !important;
}
.Menu-Navbar-Component-main {
  font-family: regular !important;
  font-size: 14px !important;
}
.Menu-Navbar-Component-setting {
  background-color: #f7f1ec !important;
  font-family: regular !important;
}

.fi-shopping-card-icon {
  font-size: 30px !important;
  /* margin-right: 50px !important; */
}
.hamburger-icon-menu-navbar {
  display: none !important;
}

@media (max-width: 768px) {
  .media-screen-class {
    display: none !important;
    background-color: #333333 !important;
  }

  .user-icon-menu-profile {
    display: none !important;
  }

  .fi-shopping-card-icon {
    margin-right: 0px !important;
  }
}

@media (max-width: 991px) {
  .img-logo-navbar-up-right-cr7 {
    margin-left: -70px !important;
  }
  .list-logo-navbar-up-right-cr7 {
    /* margin-right: -140px !important; */
    display: flex !important;
    flex-direction: row-reverse;
    gap: 10px !important;
    align-items: center !important;
    justify-content: space-around !important;
    margin-left: 100% !important;
  }
}

.active {
  color: #ff6016 !important;
}
.nav-item1 {
  color: black !important;
}

.fi-shopping-card-icon {
  font-size: 30px !important;
  /* margin-right: 50px !important; */
}

.small-circle {
  border-radius: 50% !important;
  background-color: #ff6016 !important;
  width: 18px !important;
  height: 18px !important;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: -47px !important;
  margin-left: 20px !important;
}
