.article-admin-login-main-container {
  background-image: url("../../../Assets/Background.png");
  background-size: cover;
  background-position: center;
}

.article-admin-login-main-form {
  width: 400px;
  height: 55svh;
  background-color: rgba(0, 0, 0, 0.67);
  border-radius: 5px;
  margin: auto;
  color: #efefef;
}

.img-AdminLoginLogo-form {
  height: 90px;
  width: 90px;
  margin-top: 20px;
}

.article-admin-login-main-form-container {
  gap: 20px;
  margin-top: 20px;
}

.article-admin-login-main-form-label {
  font-size: 18px;
}

.article-admin-login-main-form-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #efefef;
  outline: none;
  color: #efefef;
}

.article-admin-login-main-form-input:focus {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ff6016;
  outline: none;
  color: #efefef;
}

.admin-login-button {
  height: 40px;
  width: 150px;
  color: #efefef;
  background-color: #ff6016;
  border-radius: 5px;
  border: 1px solid #ff6016;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  padding: 5px !important;
  text-align: center !important;
}

.admin-login-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid #ff6016;
  border-radius: 5px;
  font-weight: bold;
}

.fashionzone-logo-login-page {
  height: 60px;
  width: 60px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 100;
}
