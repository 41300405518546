.category-single-component-main {
  width: 200px;
  height: 240px;
  position: relative;
  overflow: hidden;
  margin: 0 auto !important;
  border-radius: 5px;
  background-color: #efefef;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.604);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-for-category-single {
  width: 100%; /* Ensures the image doesn't exceed the width of its parent */
  height: 100%; /* Ensures the image doesn't exceed the height of its parent */

  object-fit: cover !important; /* Ensures the image covers the entire container */
}

@media (min-width: 768px) {
  .category-single-component-main:hover .dark-overlay {
    opacity: 1;
  }

  .dark-overlay img {
    object-fit: cover;
  }

  .overlay-text {
    color: white;
    /* font-size: 15px; */
    font-weight: bold;
    word-wrap: break-word;
    white-space: normal;
  }
}

@media (max-width: 768px) {
  .category-single-component-main .dark-overlay {
    opacity: 1;
  }

  .dark-overlay img {
    object-fit: cover;
  }

  .overlay-text {
    color: white;
    /* font-size: 18px !important; */
    font-weight: bold;
    word-wrap: break-word;
    white-space: normal;
  }

  .category-single-component-main {
    width: 150px;
    height: 150px;
    position: relative;

    overflow: hidden;
  }
  .image-for-category-single {
    width: 150px;
    height: 150px;
    object-fit: cover !important;
  }
}
