.canceled{
  color: rgb(84, 84, 67) !important;
}

.confirmed {
  color: rgb(9, 174, 0) !important;
}

.pending {
  color: red !important;
}

.delivered {
  color: blue !important;
}

.on-the-way {
  color: orange !important;
}

.order-history-page {
  width: 90%;
  min-height: 100svh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}
