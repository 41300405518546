.label-footer-main-container-component{
    background-color:  rgba(0, 0, 0, 0.992)!important;
}


.class-footer-main-components{
    align-items: center !important;
    width: 100vw !important;
    justify-content: center !important;
    align-self: center !important;;

}
.class-footer-main-components-row{
    padding-right: -50px !important;
    padding-left: 55px !important;
    margin-top: 60px !important;
    
    justify-content: space-between !important;

}


@media (max-width:768px){
    .class-footer-main-components-row {
        margin-right: 10% !important;
    }

    .img-img-footer{
        width: 64px !important;
        height: 64px !important;
        margin-bottom: 20px ;
    }
    
}

/* @media (max-width: 768px) {
  .class-footer-main-components {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 15px !important;
  }
} */
