.span-for-date-updated {
  font-size: 20px !important;
}

.Terms-and-condition-main-container {
  width: 85%;
  align-self: center !important;
  margin-bottom: 60px;
  margin: 0 auto;
}

.single-paragraph-terms-conditions {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: justify;
}

.p-classname-title {
  margin-top: 50px;
}
