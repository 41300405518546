.signup-page-main-container {
  background-image: url("../../Assets/Background.png");
  background-size: cover;
  background-position: center;
}

.signup-page-main-background {
  width: 750px;
  min-height: auto;
  background-color: rgba(0, 0, 0, 0.67);
  border-radius: 5px;
  margin: auto;
  color: #efefef;
}

.img-userSignupLogo-form {
  height: 60px;
  width: 60px;
  margin-top: 30px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.signup-page-register-form {
  margin-top: 20px;
}

.register-form-group {
  justify-content: space-around;
  margin-bottom: 20px;
}

.aaaa label {
  width: 125px;
  font-size: 18px;
}

@media (max-width: 700px) {
  .register-form-group {
    margin-bottom: 0px;
  }
  .register-form-group-row {
    margin-bottom: 0px;
  }
  .aaaa {
    margin-bottom: 15px;
  }
  .signup-page-main-background {
    width: 90%;
  }
  .aaaa input {
    width: 180px;
  }
}
