.manage-loc-page {
  width: 90%;
  height: 90vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.manage-loc-table {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.manage-loc-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.manage-location-button {
  height: 40px;
  width: 110px;
  color: #efefef;
  background-color: #ff6016;
  border-radius: 5px;
  border: 1px solid #ff6016;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

.manage-location-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid #ff6016;
  border-radius: 5px;
  font-weight: bold;
}

@media (max-width: 700px) {
  .manage-loc-page {
    margin-top: 100px;
  }
}
