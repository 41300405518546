.products-main-container-page {
  background-color: #000;
  min-height: 100svh;
}

.cat-sub-products-main-article {
  margin: auto;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.category-name-in-product-page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.products-active {
  color: #ff6016;
}

.products-card-container {
  width: 80%;
  margin-bottom: 60px !important;
}

@media (max-width: 768px) {
  .products-card-container {
    width: 100%;
    margin-bottom: 30px !important;
  }
}
