@font-face {
  font-family: primary;
  src: url("../src/Assets/GFSDidot-Regular.ttf");
}

@font-face {
  font-family: title;
  src: url("../src/Assets/Bungee-Regular.ttf");
}

p {
  font-family: primary;
}

h4 {
  font-family: title;
}

.body {
  min-height: 100vh;
  min-height: 100svh;
  /* padding-top: 50px;  */
}

.App {
  min-height: 70vh;
  min-height: 90svh;
  width: 100%;

  /* change this once you finish footer */
}
#root {
  min-width: 100vw !important;
  overflow-x: hidden !important;
  min-height: 90vh !important;
}
body {
  font-family: gothic !important;
  min-width: 100vw !important;
  overflow-x: hidden !important;
  min-height: 90vh !important;
}

a{
  text-decoration: none !important;
}