.single-product-main-component {
  min-height: 100svh;
  background-color: #efefef;
}

.single-product-main-content {
  margin: auto;
  margin-top: 100px;
  height: auto;
  width: 85%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 40px;
  align-self: center !important;
  align-items: center !important;
}

.carousel-item.active {
  display: flex;
  justify-content: center;
  margin: auto;
}

.carousel-image {
  width: 350px;
  height: 350px;
  object-fit: cover;
}

.carousel-image img {
  border-radius: 5px !important;
  object-fit: cover;
  margin: auto;
}

.thumbnail-image {
  border-radius: 5px !important;
}

.add-to-cart-single-product-button {
  height: 40px;
  width: 130px;
  color: #efefef;
  background-color: #ff6016;
  border-radius: 5px;
  border: 1px solid #ff6016;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.add-to-cart-single-product-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid #ff6016;
  border-radius: 5px;
  font-weight: bold;
}

.btn-outline-primary {
  color: #ff6016;
  border-color: #ff6016;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #ff6016;
  border-color: #ff6016;
}

@media (max-width: 768px) {
  .carousel-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
  }
}
