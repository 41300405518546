.home-page-main-container-header {
  min-height: 90svh;
  width: 90%;
}

.home-page-main-container {
  min-height: 100vh;
  background-image: url("../../Assets/Background.png");
  background-size: cover;
  background-position: center;
  min-width: 100svh;
  width: 100% !important;
}
.button-hero {
  width: 130px !important;
}

@font-face {
  font-family: title;
  src: url("../../Assets/Bungee-Regular.ttf");
}
.title-fashion-zone {
  font-family: title;
}

.description-hero {
  font-size: 22px;
  width: 320px;
}

.another-component {
  margin: auto !important;
  margin-top: 20px !important;
  gap: 50px !important;
  max-width: 800px;
  flex-wrap: wrap !important;
  border-bottom: 20px;
  justify-content: space-around;
  margin-bottom: 20px !important;
}

@media (max-width: 768px) {
  .another-component {
    flex-wrap: wrap !important;
  }
  .home-page-main-container-header {
    display: flex !important;
    flex-direction: column !important;
    width: 90% !important;
    justify-self: center !important;
    align-self: center !important;
    justify-content: center !important;
    align-items: flex-start !important;
  }
  .flex-text-class {
    flex-wrap: wrap !important;
  }
  .description-hero,
  .title-fashion-zone {
    width: 300px !important;
  }

  .khalil-hero {
    display: none;
  }
}
.another-color {
  color: #ff6016 !important;
}

.main-category-container-home {
  align-items: center !important;
  min-height: 60vh !important;
  flex-wrap: wrap !important;
  background-color: #efefef;
}

.collapse-list-body {
  width: 100% !important;
  border: none;
}

.collapse-list-body-desktop {
  background-color: transparent !important;
  /* color: white !important; */
}

@media (max-width: 1000px) {
  .description-hero {
    font-size: 26px;
    width: 350px;
  }
}
