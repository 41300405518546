.product-description-dashboard {
  max-width: 90% !important;
  display: flex;
  align-self: center;
}

.title-for-product-dashboard {
  color: #ff6016;
  font-size: 22px;
  font-weight: bold;
}

.product-description-dashboard {
  border-bottom: 1px solid rgba(52, 52, 88, 0.282);
  font-size: 19px;
  margin-left: 20px;
}
