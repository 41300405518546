.useredit-profile-button {
  height: 40px;
  width: 100px;
  color: #efefef;
  background-color: #ff6016;
  border-radius: 5px;
  border: 1px solid #ff6016;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

.useredit-profile-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid #ff6016;
  border-radius: 5px;
  font-weight: bold;
}

.useredit-profile-input {
  border: 0.5px solid rgba(81, 83, 104, 0.58) !important;
  outline: none !important;
  border-radius: 5px !important;
  padding: 4px !important;
}

.useredit-profile-group {
  display: flex !important;
  align-items: center !important;
}

.editprofile-cancel-button {
  height: 40px;
  width: 110px;
  color: #ff6016;
  background-color: #efefef;
  border-radius: 5px;
  border: none;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
}

.editprofile-cancel-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid #ff6016;
  border-radius: 5px;
  font-weight: bold;
}

@media (max-width: 700px) {
  .editprofile-box {
    width: 350px !important;
  }
  .useredit-profile-logo {
    display: none;
  }
  .useredit-profile-input {
    width: 90%;
  }
  .useredit-profile-group {
    gap: 3px !important;
    display: flex !important;
    align-items: center !important;
  }
}
