.contact-us-page-main-component {
  background-color: #efefef;
  /* min-height: 100vh; */
  min-height: 100svh;
}

.contact-us-page-main-logo-text {
  margin-top: 100px;
  justify-content: space-around;
  gap: 20px;
}

.contact-us-page-main-logo-text-group {
  gap: 20px;
  margin-bottom: 40px;
}

.contact-us-page-secondary-part {
  justify-content: space-around;
  width: 85%;
  margin: auto;
  gap: 20px;
}

.contact-us-page-secondary-part-form {
  justify-content: space-evenly;
}

.contact-us-submit-button {
  height: 40px;
  width: 120px;
  color: #efefef;
  background-color: #ff6016;
  border-radius: 5px;
  border: 1px solid #ff6016;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  margin-top: 20px;
}

.contact-us-submit-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid #ff6016;
  border-radius: 5px;
  font-weight: bold;
}

.contact-us-page-secondary-part-form label {
  width: 100px;
  font-weight: bold;
}

.contact-us-page-secondary-part-form input {
  border: none;
  background-color: transparent;
  border-bottom: 0.5px solid black;
  width: 200px;
  color: #ff6016;
}

.contact-us-page-secondary-part-form textarea {
  background-color: transparent;
  border-bottom: 0.5px solid black;
  width: 300px;
  height: 150px;
  color: #ff6016;
}

.contact-us-page-secondary-part-form input:hover,
.contact-us-page-secondary-part-form input:focus,
.contact-us-page-secondary-part-form textarea:hover,
.contact-us-page-secondary-part-form textarea:focus {
  border: none;
  border-bottom: 0.5px solid #ff6016;
  outline: none;
}

.contact-us-page-secondary-part-form-last-child {
  margin-top: 20px;
}

.contact-us-article-submit-button {
  justify-content: flex-end;
}

@media (max-width: 900px) {
  .contact-us-page-secondary-part {
    flex-direction: column-reverse !important;
    width: 85% !important;
    align-items: center;
  }

  .contact-us-page-secondary-part-image {
    width: 350px !important;
    height: 220px !important;
  }

  .contact-us-submit-button {
    margin-bottom: 20px;
  }
}

@media (max-width: 700px) {
  .contact-us-page-secondary-part-form {
    width: 85%;
  }
}
