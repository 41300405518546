.user-profile-page-main-container {
  min-height: 100svh;
  background-color: #efefef;
}

.user-profile-page-content {
  margin-top: 100px;
  height: auto;
}

.test {
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.user-profile-button {
  height: 40px;
  width: 120px;
  color: #efefef;
  background-color: #ff6016;
  border-radius: 5px;
  border: 1px solid #ff6016;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  margin-top: 20px;
}

.user-profile-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid #ff6016;
  border-radius: 5px;
  font-weight: bold;
}

.user-profile-manage-loc-button {
  height: 40px;
  width: 160px;
  color: #efefef;
  background-color: #ff6016;
  border-radius: 5px;
  border: 1px solid #ff6016;
  transition: background 0.7s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 40px;
}

.user-profile-manage-loc-button:hover {
  color: #ff6016;
  background-color: black;
  border: 1px solid #ff6016;
  border-radius: 5px;
  font-weight: bold;
}
.user-profile-left-side {
  align-items: center;
  gap: 20px;
}

.user-profile-left-side-text {
  color: rgba(0, 0, 0, 0.66);
}

.user-profile-right-side-backgroud {
  width: 600px;
  align-items: center;
  height: auto;
}

@media (max-width: 700px) {
  .test {
    display: flex !important;
    flex-direction: column !important;
    height: auto;
  }
  .user-profile-page-content {
    margin-top: 100px;
  }
  .user-profile-right-side-text {
    margin-top: 20px !important;
  }
  .user-profile-button {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .user-profile-right-side-backgroud {
    width: 90% !important;
  }
  .userprofile-group {
    width: 100%;
    justify-content: center;
  }
}
