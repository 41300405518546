@media (max-width: 700px) {
  .new-location-box {
    width: 320px !important;
  }

  .new-location-input {
    width: 90% !important;
  }

  .new-location-group {
    gap: 3px !important;
    display: flex !important;
    align-items: center !important;
  }
}

.new-location-input {
  border: 0.5px solid rgba(81, 83, 104, 0.58) !important;
  outline: none !important;
  border-radius: 5px !important;
  padding: 4px !important;
}
