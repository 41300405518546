.about-us-page-main-component {
  background-color: #efefef;
  min-height: 100svh;
  margin: auto;
}

.about-us-page-main-content {
  margin: auto;
  margin-top: 100px;
  justify-content: space-around;
  width: 90%;
  gap: 20px;
  margin-bottom: 20px !important;
}

.about-us-page-main-content-row {
  justify-content: space-around;
  align-items: center;
}

.about-us-page-main-content-row-image {
  width: 50%;
  border-radius: 5px;
}

.about-us-page-main-content-title-text {
  gap: 20px;
  width: 50%;
}

.about-us-page-main-content-title {
  display: flex;
  justify-content: center;
}

.about-us-page-main-content-row-text {
  text-align: justify;
  width: 90%;
}

@media (max-width: 900px) {
  .about-us-page-main-content-title-text,
  .about-us-page-main-content-title,
  .about-us-page-main-content-row-text,
  .about-us-page-main-content-row-image {
    flex-direction: column !important;
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    gap: 0;
  }

  .about-us-page-main-content-row {
    margin-bottom: 20px;
  }

  .about-us-page-main-content-row-text {
    margin-bottom: 20px;
  }

  .about-us-page-main-content-title h1 {
    display: flex !important;
    justify-content: center !important;
  }
}
