.cart-shop-main-component {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.283);
  padding-bottom: 10px;
}

/* .product-description-details {
  padding: 0 !important;
  margin: 0 !important;
} */

.remove-icon-product {
  font-size: 30px !important;
  color: rgba(0, 0, 0, 0.42);
  cursor: pointer;
}

.price-product-detail {
  font-size: 25px;
  font-weight: 400;
}

.color-for-quantity-product {
  color: #ff6016;
  font-size: 22px;
}

/* offcanvas footer */
.offcanvas-footer-total {
  /* border: 1px solid black; */
  margin-top: 30px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 20px;
  width: 90%;
  background-color: rgba(128, 128, 128, 0.095);
  border-radius: 5px;
  padding: 20px;
}

.price-product-detail-title {
  font-size: 20px;
  font-weight: bold;
}
